<template>
  <panel title="Service Rate">
    <!-- use component -->
    <service-rate-form-modal
      ref="serviceRateFormModal"
      @reload="$refs.tableServiceRateData.reload()"
    />
    <!-- end use component -->

    <data-table-local
      ref="tableServiceRateData"
      :columns="_columns"
      url="/api/serviceRate"
      :viewAble="false"
      @clickNew="$refs.serviceRateFormModal.show('')"
      @clickEdit="$refs.serviceRateFormModal.show(cp($event))"
      ableActionDelete
    />
  </panel>
</template>
<script>
export default {
  components: {
    ServiceRateFormModal: () =>
      import("./components/Index/ServiceRateFormModal.vue"),
  },
  computed: {
    _columns() {
      return [
        {
          label: "Type",
          field: "service_type_data.name_th",
          thClass: "text-center",
          type: "string",
        },
        {
          label: "Credit Rate",
          field: "amount",
          thClass: "text-center",
          type: "number",
        },
      ]
    },
  },
}
</script>
